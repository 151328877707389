import { useContext, useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'

import {
  Topbar, LeftSideContainer, NavigationContainer, NavigationLink,
  NavigationText, Logo, RightSideContainer, ActionsContainer, Divider,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  DropdownText, UserContainer, UserName, UserPhoto, ShowMobile,
   TopbarMenuDesktop, TopbarMenuMobile
} from './styled'

import { BiLogOut } from 'react-icons/bi'

import { BeautifulName } from 'beautiful-name'

import { FaUsers, FaChartArea, FaImages, FaLayerGroup, FaIndustry, FaHome, FaBusinessTime, FaObjectUngroup } from 'react-icons/fa'
import { AiOutlineUser, AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'

import BtnOrange from 'components/Button/BtnOrange'

import { FaBars } from "react-icons/fa"
import { Context } from 'contexts/context'
import { logout } from 'services/auth'

import api from "../../services/api/api";


const Component = (props) => {
  const history = useHistory()

  const [isReportPage, setIsReportPage] = useState(false)

  const { toggle, setToggle, user, report, isLoaded } = useContext(Context)

  const userName = new BeautifulName(user.name).firstName
  const userImage = user.file_url ? `${process.env.REACT_APP_URL_API}/${user.file_url}` : '/user.svg'

  const handleLogout = async () => {
    try {
      await api.delete("/users/logout")
    } catch (error) {

    } finally {
      logout(history)
    }
  }

  useEffect(() => {
    const pathNameSplit = history.location.pathname.split("/")
    
    setIsReportPage(pathNameSplit.includes("grupos") && pathNameSplit.includes("relatorios") && pathNameSplit.includes("datasets"))
  }, [history])

  return (
    <Topbar>
      <TopbarMenuMobile
        onClick={() => setToggle(!toggle)}
        className={toggle ? 'menu-open' : 'menu-closed'}
      >
        <span>{toggle ? <AiOutlineClose /> : <AiOutlineMenu />}</span>
      </TopbarMenuMobile>
      <LeftSideContainer>
        {/* <Link to='/'><Logo src={`${process.env.REACT_APP_URL_APP}/logo-big.png`} alt="Exph" /></Link> */}

        {/* <NavigationContainer>

          {user.roles === 'admin' ? (
            <>
              <NavigationLink show style={{ display: "block !important" }} to="/">
                <FaIndustry fontSize={20} color={"#04110A"} />
                <NavigationText style={{ color: "#04110A" }}>Relátórios</NavigationText>
              </NavigationLink>

              <NavigationLink to="/usuarios">
                <FaUsers fontSize={20} color={"#04110A"} />
                <NavigationText style={{ color: "#04110A" }}>Usuários</NavigationText>
              </NavigationLink>

              <NavigationLink to="/empresas">
                <FaBusinessTime fontSize={20} color={"#04110A"} />
                <NavigationText style={{ color: "#04110A" }}>Empresas</NavigationText>
              </NavigationLink>

              <NavigationLink to="/cards">
                <FaImages fontSize={20} color={"#04110A"} />
                <NavigationText style={{ color: "#04110A" }}>Cards</NavigationText>
              </NavigationLink>

              <NavigationLink to="/colecoes">
                <FaLayerGroup fontSize={20} color={"#04110A"} />
                <NavigationText style={{ color: "#04110A" }}>Grupos</NavigationText>
              </NavigationLink>

              <NavigationLink to="/categorias">
                <FaObjectUngroup fontSize={20} color={"#04110A"} />
                <NavigationText style={{ color: "#04110A" }}>Categorias</NavigationText>
              </NavigationLink>

              <NavigationLink to="/analytics">
                <FaChartArea fontSize={20} color={"#04110A"} />
                <NavigationText style={{ color: "#04110A" }}>Analytics</NavigationText>
              </NavigationLink>
            </>
          ) : (
            <>
              <NavigationLink show style={{ display: "block !important" }} to="/relatorios">
                <FaIndustry fontSize={20} color={"#04110A"} />
                <NavigationText style={{ color: "#04110A" }}>Relátórios</NavigationText>
              </NavigationLink>
            </>
          )}
        </NavigationContainer> */}

        {/* <ShowMobile>
          {user.roles === 'admin' ? (
            <Dropdown>
              <DropdownToggle color={"#fff"}>
                <AiOutlineMenu fontSize={20} color={"#04110A"} />
                {/* <span style={{ color: "#fff", marginLeft: ".5rem" }}>Menu</span> 
              </DropdownToggle>

              <DropdownMenu x="-60px" y="48px">


                <DropdownItem onClick={() => history.push("/")}>
                  <FaIndustry fontSize={20} color={"black"} />
                  <DropdownText style={{ color: "black" }}>Relátórios</DropdownText>
                </DropdownItem>

                <DropdownItem onClick={() => history.push("/usuarios")}>
                  <FaUsers fontSize={20} color={"black"} />
                  <DropdownText style={{ color: "black" }}>Usuários</DropdownText>
                </DropdownItem>

                <DropdownItem onClick={() => history.push("/empresas")}>
                  <FaBusinessTime fontSize={20} color={"black"} />
                  <DropdownText style={{ color: "black" }}>Empresas</DropdownText>
                </DropdownItem>

                <DropdownItem onClick={() => history.push("/cards")}>
                  <FaImages fontSize={20} color={"black"} />
                  <DropdownText style={{ color: "black" }}>Cards</DropdownText>
                </DropdownItem>

                <DropdownItem onClick={() => history.push("/colecoes")}>
                  <FaLayerGroup fontSize={20} color={"black"} />
                  <DropdownText style={{ color: "black" }}>Grupos</DropdownText>
                </DropdownItem>

                <DropdownItem onClick={() => history.push("/categorias")}>
                  <FaObjectUngroup fontSize={20} color={"black"} />
                  <DropdownText style={{ color: "black" }}>Categorias</DropdownText>
                </DropdownItem>

                <DropdownItem onClick={() => history.push("/analytics")}>
                  <FaChartArea fontSize={20} color={"black"} />
                  <DropdownText style={{ color: "black" }}>Analytics</DropdownText>
                </DropdownItem>


                <>
                  <DropdownItem onClick={() => history.push("/relatorios")}>
                    <FaIndustry fontSize={20} color={"black"} />
                    <DropdownText style={{ color: "black" }}>Relátórios</DropdownText>
                  </DropdownItem>
                </>
              </DropdownMenu>
            </Dropdown>
          ) : (
            <DropdownItem onClick={() => history.push("/relatorios")}>
              <FaIndustry fontSize={20} color={"black"} />
            </DropdownItem>
          )}
        </ShowMobile> */}
      </LeftSideContainer>

      <RightSideContainer>
        {isLoaded && (user.view_report_options === 1 || user.roles === "admin") && (
          <>
            <ActionsContainer>
              {/* <PersonalizedDropdown
                isLoading={isDownloading}
                text="Exportar"
                style={{
                  height: "23px",
                  marginRight: "1rem"
                }}
                btSize='10pt'
                items={[
                  {
                    text: "PNG",
                    onClick: () => handleExport({ format: "PNG", extension: "zip" })
                  },
                  {
                    text: "PDF",
                    onClick: () => handleExport({ format: "PDF", extension: "pdf" })
                  }
                ]}
              /> */}

              <BtnOrange
                onClick={() => report.fullscreen()}
                isLoading={false}
                style={{
                  width: "23px",
                  height: "23px",
                  marginRight: ".5rem",
                  padding: 0,
                  backgroundColor: "#04110A"
                }}
              >
                <img
                  src="/svgs/fullscreen.svg"
                  className='imageButtonReport'
                  alt={"icon"}
                />
              </BtnOrange>



              <BtnOrange
                onClick={() => report.print()}
                isLoading={false}
                style={{
                  width: "23px",
                  height: "23px",
                  marginRight: ".5rem",
                  padding: 0,
                  backgroundColor: "#04110A"
                }}
              >
                <img
                  src="/svgs/print.svg"
                  className='imageButtonReport'
                  alt={"icon"}
                />
              </BtnOrange>

              <BtnOrange
                onClick={() => report.refresh()}
                isLoading={false}
                style={{
                  width: "23px",
                  height: "23px",
                  padding: 0,
                  backgroundColor: "#04110A"
                }}
              >
                <img
                  src="/svgs/autorenew.svg"
                  className='imageButtonReport'
                  alt={"icon"}
                />
              </BtnOrange>
            </ActionsContainer>

            <Divider style={{ backgroundColor: "#04110A" }} />
          </>
        )}

        <Dropdown>
          <DropdownToggle color={"#04110A"}>
            <UserContainer>
              <UserName style={{ color: "#04110A" }}>Olá, {userName}!</UserName>
              <UserPhoto src={userImage} alt={`Foto de ${userName}`} />
            </UserContainer>
          </DropdownToggle>

          <DropdownMenu x="0px" y="58px">
            <DropdownItem onClick={() => history.push("/profile")}>
              <AiOutlineUser fontSize={20} color={"black"} />
              <DropdownText style={{ color: "black" }}>Perfil</DropdownText>
            </DropdownItem>

            <DropdownItem onClick={() => handleLogout(history)}>
              <BiLogOut fontSize={20} color={"black"} />
              <DropdownText style={{ color: "black" }}>Sair</DropdownText>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </RightSideContainer>
      {/* <TopbarMenuDesktop onClick={() => setToggle(!toggle)}>
        {!props.error && (
          <span style={{ marginLeft: toggle ? "200px" : "100px" }}><FaBars /></span>
        )}
      </TopbarMenuDesktop>
      <TopbarMenuMobile
        onClick={() => setToggle(!toggle)}
        className={toggle ? 'menu-open' : 'menu-closed'}
      >
        {!props.error && (
          <span><FaBars /></span>
        )}
      </TopbarMenuMobile>
      <TopbarActions>
        <div>
          <Logout onClick={() => handleLogout(history)}>Sair</Logout>
        </div>
      </TopbarActions> */}
    </Topbar>
  )
}

export default Component
