import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Context } from 'contexts/context'

import Topbar from 'components/Topbar'
import NewTopbar from 'components/NewTopbar'
import Sidebar from 'components/Sidebar'
import BtnOrange from 'components/Button/BtnOrange'

import { getUser, logout } from 'services/auth'

import { Body, Content, ContentWrapper } from './styled'

const ErrorComponent = () => {
    return (
        <div 
            style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center' ,
                flexDirection: 'column',
                height: '70vh'
            }}
        >
            <h1 
                style={{ 
                    fontSize: '2rem', 
                    marginBottom: '1rem',
                    textAlign: 'center'
                }}
            >
                Sistema inativo no momento
            </h1>

            <BtnOrange
                onClick={() => window.location.reload(false)}
            >
                Tentar Novamente
            </BtnOrange>
        </div>
    )
}

const Base = ChildrenComponent => {
    const ComponentBase = props => {
        const { toggle, user, setIsJustOneReport } = useContext(Context)

        const history = useHistory()

        useEffect(() => {
            setInterval(() => {
                getUser().catch(() => logout(history))
            }, 1000 * 60)
        }, [])

        return (
            <Body>
                {!props.error && <Sidebar />}

                <ContentWrapper>
                    {user.roles === "admin" ? <Topbar error={props.error} /> : <NewTopbar />}
                    
                    <Content style={{ marginTop: user.roles === "admin" ? "70px" : "0px" }} toggle={toggle}>
                        {props.error ? (
                            <ErrorComponent />
                        ) : <ChildrenComponent {...props} />}
                    </Content>
                </ContentWrapper>
            </Body>
        )
    }
    
    return ComponentBase
}

export default Base