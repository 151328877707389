import styled from 'styled-components'

export const Body = styled.div`
    display: flex;
    background-color: var(--color-background);
`
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    /* margin-top: 70px; */
    flex-grow: 1;

    @media (min-width: 1141px) {
        /* margin-left: ${props => props.toggle ? '180px' : '80px'};  */
    }
`
export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* margin-left: 3em; */
    flex-grow: 1;
`